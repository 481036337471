var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CCard',[_c('CCardBody',[_c('div',{staticClass:"clearfix"},[_c('button',{staticClass:"btn btn-primary float-xl-right",attrs:{"type":"button"},on:{"click":_vm.navToNewSymptoms}},[_vm._v(" New Option ")])]),_c('CDataTable',{ref:"vuetable",attrs:{"items":_vm.items,"fields":_vm.fields,"striped":"","clickable-rows":"","column-filter":"","table-filter":"","items-per-page-select":"","items-per-page":50,"sorter":"","pagination":""},scopedSlots:_vm._u([{key:"optionType-filter",fn:function(){return [_c('select',{staticClass:"form-control form-control-sm",on:{"input":function($event){return _vm.$refs.vuetable.columnFilterEvent(
                'optionType',
                $event.target.value,
                'input'
              )}}},[_c('option',{attrs:{"value":"","selected":"selected"}},[_vm._v("Any")]),_c('option',{attrs:{"value":"1"}},[_vm._v("Input Condition")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Input Reading")])])]},proxy:true},{key:"index",fn:function(data){return [_c('td',[_vm._v(" "+_vm._s(data.index + 1)+" ")])]}},{key:"createdTime",fn:function({ item }){return [(item.createdTime)?_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(_vm._f("dateParse")(item.createdTime),"MMM D, YYYY hh:mm A"))+" ")]):_c('td',[_vm._v("--")])]}},{key:"optionType",fn:function({ item }){return [(item.optionType == 1)?_c('td',{staticClass:"font-weight-bold"},[_vm._v(" Input Condition ")]):_c('td',{staticClass:"font-weight-bold"},[_vm._v("Input Reading")])]}},{key:"show_details",fn:function({ item }){return [_c('td',{staticClass:"py-2"},[_c('CDropdown',{attrs:{"togglerText":"Actions","color":"info"}},[_c('CDropdownItem',{attrs:{"to":{
                  name: 'Option',
                  params: {
                    checkOptionId: item.checkOptionId,
                    checkId: item.checkId,
                    mode: 'view',
                  },
                }}},[_vm._v("View")]),_c('ConfirmationModal',{attrs:{"parent":_vm.$refs.modalArea,"description":'Are you sure to Delete'},on:{"on:ok":function($event){return _vm.deleteFaq(item.checkOptionId)}}})],1)],1)]}}])})],1),_c('div',{ref:"modalArea"}),_c('CCardFooter',{attrs:{"align":"right"}},[_c('CButton',{staticClass:"mr-2",attrs:{"type":"Cancel","size":"sm","color":"danger"},on:{"click":function($event){return _vm.cancel()}}},[_c('CIcon',{attrs:{"name":"cil-ban"}}),_vm._v(" Cancel ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }